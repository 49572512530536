<template>
  <v-list
    class="pt-0"
    dense
  >
    <v-subheader
      class="primary subtitle-2"
      dark
    >
      <span class="u-text-overflow">{{
        item.budget_product.product.product_name
      }}</span>
    </v-subheader>

    <v-list-item
      :disabled="item.art_src == null"
      @click.stop="seeArt"
      class="mt-2"
      link
    >
      <v-list-item-icon>
        <v-icon :class="{ 'grey--text text--lighten-1': item.art_src == null }"
          >fas fa-eye</v-icon
        >
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title v-text="'Ver arte'" />
      </v-list-item-content>
    </v-list-item>

    <v-list-item
      v-if="item.order_product_status == 41 || item.order_product_status == 411"
      @click.stop="openDialog('in-purchase')"
      class="mt-2"
      link
    >
      <v-list-item-icon>
        <v-icon>fas fa-shopping-bag</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title v-text="'Comprar'" />
      </v-list-item-content>
    </v-list-item>

    <v-list-item
      v-if="item.order_product_status == 42 || item.order_product_status == 421"
      @click.stop="openDialog('in-printed')"
      class="mt-2"
      link
    >
      <v-list-item-icon>
        <v-icon>fas fa-stamp</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title v-text="'Enviar para gravação'" />
      </v-list-item-content>
    </v-list-item>

    <v-list-item
      v-if="item.order_product_status == 43 || item.order_product_status == 431"
      @click.stop="openDialog('in-check')"
      class="mt-2"
      link
    >
      <v-list-item-icon>
        <v-icon>fas fa-tasks</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title v-text="'Conferir'" />
      </v-list-item-content>
    </v-list-item>

    <v-list-item
      v-if="
        (item.order_product_status == 44 || item.order_product_status == 441) &&
        showOrderProductsProduceStep
      "
      @click.stop="produce"
      class="mt-2"
      link
    >
      <v-list-item-icon>
        <v-icon>fas fa-cogs</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title v-text="'Produzir'" />
      </v-list-item-content>
    </v-list-item>

    <v-list-item
      v-if="item.order_product_status == 441"
      @click.stop="updateProductToInvoicing"
      class="mt-2"
      link
    >
      <v-list-item-icon>
        <v-icon>fas fa-arrow-right</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title v-text="'Passar para faturamento'" />
      </v-list-item-content>
    </v-list-item>

    <v-list-item
      v-if="item.order_product_status == 441 || item.order_product_status >= 45"
      @click.stop="seeProductionHistory"
      class="mt-2"
      link
    >
      <v-list-item-icon>
        <v-icon>fas fa-history</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title v-text="'Histórico de produção'" />
      </v-list-item-content>
    </v-list-item>

    <v-dialog
      width="800"
      v-model="isOpened"
      scrollable
    >
      <component
        @closeDialog="cancel"
        :is="currentComponent"
        :readOnly="readOnly"
        :item="item"
      />
    </v-dialog>
    <v-dialog
      width="600"
      v-model="openedArtDialog"
    >
      <v-card
        dark
        flat
      >
        <v-img
          :src="$store.state.imagePath + item.art_src"
          :lazy-src="$store.state.imagePath + item.art_src"
          max-width="100%"
          max-height="600"
          contain
        >
          <v-btn
            class="white"
            @click="closeArtDialog"
            absolute
            top
            right
            icon
            color="error"
          >
            <v-icon>fas fa-times</v-icon>
          </v-btn>
        </v-img>
      </v-card>
    </v-dialog>
  </v-list>
</template>

<script>
import { contextMenu } from '@/main.js'
import { mapGetters } from 'vuex'
export default {
  components: {
    InPurchase: () => import('./products-status/InPurchase.vue'),
    InPrinted: () => import('./products-status/InPrinted.vue'),
    InCheck: () => import('./products-status/InCheck.vue'),
    InProduction: () => import('./products-status/InProduction.vue'),
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isOpened: false,
      currentComponent: null,
      openedArtDialog: false,
      readOnly: false,
    }
  },
  computed: {
    ...mapGetters('comercial', ['showOrderProductsProduceStep']),
  },
  methods: {
    seeArt() {
      this.openedArtDialog = true
    },
    closeArtDialog() {
      this.openedArtDialog = false
      this.$emit('closeMenu')
    },
    seeProductionHistory() {
      this.readOnly = true
      this.openDialog('in-production')
    },
    produce() {
      this.readOnly = false
      this.openDialog('in-production')
    },
    updateProductToInvoicing() {
      this.$api
        .patch(`orders/products/invoicing/${this.item.id}`)
        .then(() => {
          this.item.order_product_status = 45
          this.$emit('closeMenu')
        })
        .catch((err) => {
          console.error(err)
        })
    },
    openDialog(component) {
      this.currentComponent = component
      this.isOpened = true
    },
    cancel() {
      this.isOpened = false
      this.$emit('closeMenu')
      contextMenu.action('order-products')
    },
  },
}
</script>
